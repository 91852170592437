// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BA7uaX0dJLbGBXRNWAu1{padding:0 24px;font-size:13px}.E0IogDXpBuloG8qxkDY6{text-transform:capitalize;font-weight:bold}.Uu8P1QVuwpDJNlmkRDqH{font-weight:bold;color:#90959f}.Gk5FyOxjsB05Z07l4gAP{font-weight:bold;color:#acacb6}.e0cpXZzSeh88wQ6OfJoi{margin-bottom:4px}`, "",{"version":3,"sources":["webpack://./src/components/cards/AssetListingCard/components/CardBody/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,cAAA,CACA,cAAA,CACF,sBACE,yBAAA,CACA,gBAAA,CACF,sBACE,gBAAA,CACA,aAAA,CACF,sBACE,gBAAA,CACA,aAAA,CACF,sBACE,iBAAA","sourcesContent":[".body\n  padding: 0 24px\n  font-size: 13px\n.author\n  text-transform: capitalize\n  font-weight: bold\n.colorPublisher\n  font-weight: bold\n  color: #90959f\n.colorBody\n  font-weight: bold\n  color: #acacb6\n.mb-sm\n  margin-bottom: 4px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `BA7uaX0dJLbGBXRNWAu1`,
	"author": `E0IogDXpBuloG8qxkDY6`,
	"colorPublisher": `Uu8P1QVuwpDJNlmkRDqH`,
	"colorBody": `Gk5FyOxjsB05Z07l4gAP`,
	"mb-sm": `e0cpXZzSeh88wQ6OfJoi`
};
export default ___CSS_LOADER_EXPORT___;
