// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.q7TAT1gc1RMKSN8gbeVg{display:flex;justify-content:space-between;align-items:flex-start;padding:0px 24px}.nMUq2t6qSDLZQ7XsOAfK{display:flex;flex-direction:column;align-items:flex-start;gap:4px}.j1Ebq9pN930qan4fB8yP{border-radius:0 !important}.j1Ebq9pN930qan4fB8yP.osrrc845SBVgbSvp9xqH{height:25px !important;width:110px !important}.E0aXsBAh0B9a9IQREhvd{display:flex;flex-direction:column}.xcOXd2EnWaAqJi_lQ_NX{font-size:13px;line-height:1.2}.rY42ek4yslsOisXg6gaC{font-weight:bold}.ZHkkB5QJLqhzGEmp4z6w{font-weight:bold}.vVkYcmJY3Wi09SwIZimY{font-weight:bold}`, "",{"version":3,"sources":["webpack://./src/components/cards/AssetListingCard/components/CardFooter/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,6BAAA,CACA,sBAAA,CACA,gBAAA,CAEF,sBACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,OAAA,CAEF,sBACE,0BAAA,CACA,2CACE,sBAAA,CACA,sBAAA,CAEJ,sBACE,YAAA,CACA,qBAAA,CAEF,sBACE,cAAA,CACA,eAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,gBAAA,CAEF,sBACE,gBAAA","sourcesContent":[".footer\n  display: flex\n  justify-content: space-between\n  align-items: flex-start\n  padding: 0px 24px\n\n.info\n  display: flex\n  flex-direction: column\n  align-items: flex-start\n  gap: 4px\n\n.button\n  border-radius: 0 !important\n  &.cardAsLink\n    height: 25px !important\n    width: 110px !important\n\n.item\n  display: flex\n  flex-direction: column\n\n.combined\n  font-size: 13px\n  line-height: 1.2\n\n.label\n  font-weight: bold\n\n.value\n  font-weight: bold\n\n.valueId\n  font-weight: bold\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": `q7TAT1gc1RMKSN8gbeVg`,
	"info": `nMUq2t6qSDLZQ7XsOAfK`,
	"button": `j1Ebq9pN930qan4fB8yP`,
	"cardAsLink": `osrrc845SBVgbSvp9xqH`,
	"item": `E0aXsBAh0B9a9IQREhvd`,
	"combined": `xcOXd2EnWaAqJi_lQ_NX`,
	"label": `rY42ek4yslsOisXg6gaC`,
	"value": `ZHkkB5QJLqhzGEmp4z6w`,
	"valueId": `vVkYcmJY3Wi09SwIZimY`
};
export default ___CSS_LOADER_EXPORT___;
