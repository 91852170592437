// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kyKGviR253cLVEQKH7N3{height:200px;width:auto;overflow:hidden;background:rgba(0,0,0,.0509803922);margin:20px 20px 0;position:relative}.CtZqeINg4OlV67MH4zW8{border:1px solid rgba(27,38,70,.2);border-radius:5px;background:#fff;overflow:hidden}.HCWFrbl4CO3S4L8_bc7g{box-shadow:0 3px 6px rgba(0,0,0,.1607843137)}.rTsJrxHPHCTba6bUxY82{padding:0;width:400px;height:470px;position:relative}.UuRUcKuZVFwcbAZHUnNL{text-transform:uppercase;font-weight:bold;line-height:0;margin-top:10px;align-items:center;padding:5px 10px}.qnv5nBKQlKG87ZQ9sDWw{padding:0 24px;font-size:16px;font-weight:600;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;margin-top:5px}`, "",{"version":3,"sources":["webpack://./src/components/cards/AssetListingCard/components/CustomCard/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,eAAA,CACA,kCAAA,CACA,kBAAA,CACA,iBAAA,CACF,sBACE,kCAAA,CACA,iBAAA,CACA,eAAA,CACA,eAAA,CACF,sBACE,4CAAA,CACF,sBACE,SAAA,CACA,WAAA,CACA,YAAA,CACA,iBAAA,CACF,sBACE,wBAAA,CACA,gBAAA,CACA,aAAA,CACA,eAAA,CACA,kBAAA,CACA,gBAAA,CACF,sBACE,cAAA,CACA,cAAA,CACA,eAAA,CACA,eAAA,CACA,kBAAA,CACA,sBAAA,CACA,cAAA","sourcesContent":[".imageLogo\n  height: 200px\n  width: auto\n  overflow: hidden\n  background: #0000000d\n  margin: 20px 20px 0\n  position: relative\n.cardCustom\n  border: 1px solid rgba(27, 38, 70, .2)\n  border-radius: 5px\n  background: #fff\n  overflow: hidden\n.shadowCard\n  box-shadow: 0 3px 6px #00000029\n.customCard\n  padding: 0\n  width: 400px\n  height: 470px\n  position: relative\n.customCardTitle\n  text-transform: uppercase\n  font-weight: bold\n  line-height: 0\n  margin-top: 10px\n  align-items: center\n  padding: 5px 10px\n.customTitle\n  padding: 0 24px\n  font-size: 16px\n  font-weight: 600\n  overflow: hidden\n  white-space: nowrap\n  text-overflow: ellipsis\n  margin-top: 5px\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageLogo": `kyKGviR253cLVEQKH7N3`,
	"cardCustom": `CtZqeINg4OlV67MH4zW8`,
	"shadowCard": `HCWFrbl4CO3S4L8_bc7g`,
	"customCard": `rTsJrxHPHCTba6bUxY82`,
	"customCardTitle": `UuRUcKuZVFwcbAZHUnNL`,
	"customTitle": `qnv5nBKQlKG87ZQ9sDWw`
};
export default ___CSS_LOADER_EXPORT___;
