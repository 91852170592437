// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WTRXt8n0K9b6jbtNgZiq{color:#fff !important;text-transform:uppercase;font-size:16px}.KGlIk8puYRuCrJrCJUre{background:#ef4444 !important}.vGOq9O7rxVW3owCeXQb1{background:#24a451 !important}`, "",{"version":3,"sources":["webpack://./src/components/tags/TagActiveInactive/styles.module.sass"],"names":[],"mappings":"AAAA,sBACE,qBAAA,CACA,wBAAA,CACA,cAAA,CACF,sBACE,6BAAA,CACF,sBACE,6BAAA","sourcesContent":[".tag\n  color: #fff !important\n  text-transform: uppercase\n  font-size: 16px\n.inactive\n  background: #ef4444 !important\n.active\n  background: #24a451 !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tag": `WTRXt8n0K9b6jbtNgZiq`,
	"inactive": `KGlIk8puYRuCrJrCJUre`,
	"active": `vGOq9O7rxVW3owCeXQb1`
};
export default ___CSS_LOADER_EXPORT___;
